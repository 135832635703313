/*******************************************************
    Navigation general
*******************************************************/
.menu {
	&.inline {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	&.inline-stretch {
		display: flex;
		flex-direction: row;
		align-items: stretch;
	}
	
	&.stack {
		// display: flex;
		// flex-direction: column;
	}
}

/*******************************************************
    Menu navbar
*******************************************************/
.main-nav-background {
	position: relative;

	.nav-background {
		display: block;
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		background-color: $color__white;
		width: 100%;
	}
	
	&.height-noscroll {
		.nav-background {
			height: 200px;

			@media (screen(lg)) {
				height: 100px;
			}
			
			@media (screen(xl)) {
				height: 140px;
			}
		}
	}
	
	&.height-scroll {
		.nav-background {
			height: 70px;

			@media (screen(lg)) {
				height: 70px;
			}
		}
	}
}

.after-triangle {
	position: relative;

	&:after {
		position: absolute;
		display: block;
		content: "";
		width: 0;
		height: 0;
		border-top: 6px solid transparent;
		border-left: 12px solid $color__white;
		border-bottom: 6px solid transparent;

		@media (screen(md)) {
			border-top: 10px solid transparent;
			border-left: 20px solid $color__white;
			border-bottom: 10px solid transparent;
		}
		
		@media (screen(xl)) {
			border-top: 14px solid transparent;
			border-left: 28px solid $color__white;
			border-bottom: 14px solid transparent;
		}
	}
	
	.window-scroll & {
		@media (screen(xl)) {
			&:after {
				border-top: 10px solid transparent;
				border-left: 20px solid $color__white;
				border-bottom: 10px solid transparent;
			}
		}
	}
}

/*******************************************************
    Mobile menu
*******************************************************/