* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	width: 100%;
	background-color: $color__white;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

.page__wrapper {
	// overflow-x: hidden; /* fix for ios safari */
}