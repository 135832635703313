// .roboto-thin {
//   font-family: "Roboto", sans-serif;
//   font-weight: 100;
//   font-style: normal;
// }
//
// .roboto-regular {
//   font-family: "Roboto", sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }
//
// .roboto-bold {
//   font-family: "Roboto", sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }
// <uniquifier>: Use a unique and descriptive class name
// .roboto-condensed-<uniquifier> {
//   font-family: "Roboto Condensed", serif;
//   font-optical-sizing: auto;
//   font-weight: 400;
//   font-style: normal;
// }
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900
// .roboto-slab-<uniquifier> {
//   font-family: "Roboto Slab", serif;
//   font-optical-sizing: auto;
//   font-weight: <weight>;
//   font-style: normal;
// }
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto+Slab:wght@100..900&family=Roboto:wght@100;400;700&display=swap');