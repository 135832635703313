@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
// Include any default variable overrides here
@import 'variables';
/* --------------------------------------------------------------
# Fontawesome Pro 6.4
-------------------------------------------------------------- */
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
// @import '~@fortawesome/fontawesome-pro/scss/thin';
// @import '~@fortawesome/fontawesome-pro/scss/light';
// @import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
// @import '~@fortawesome/fontawesome-pro/scss/duotone';
 @import '~@fortawesome/fontawesome-pro/scss/brands';
// @import '~@fortawesome/fontawesome-pro/scss/sharp-light';
// @import '~@fortawesome/fontawesome-pro/scss/sharp-solid';
// @import '~@fortawesome/fontawesome-pro/scss/sharp-regular';
/* --------------------------------------------------------------
# Swiper 11.0.1
-------------------------------------------------------------- */
// imported in JS
// @import '~swiper/swiper-bundle.css';
/* --------------------------------------------------------------
# Lightgallery
-------------------------------------------------------------- */
// @import '~lightgallery/css/lightgallery.css';
// @import '~lightgallery/css/lg-fullscreen.css';
// @import '~lightgallery/css/lg-zoom.css';
// @import '~lightgallery/css/lg-thumbnail.css';
/*******************************************************
    Reset
*******************************************************/
@import 'reset';
/*******************************************************
    Global
*******************************************************/
@import 'global';
/*******************************************************
    Font
*******************************************************/
@import 'fonts';
/*******************************************************
    Typography
*******************************************************/
@import 'typography';
/*******************************************************
    Tailwind overwrite
*******************************************************/
@import '_tailwind-overwrite';
/*******************************************************
    Colors
*******************************************************/
@import 'colors';
/*******************************************************
	Text Editor
*******************************************************/
@import 'text-editor';
/*******************************************************
    Utilities
*******************************************************/
// @import 'utilities';
/*******************************************************
    Navigation
*******************************************************/
@import 'navigation';
/*******************************************************
    Forms
*******************************************************/
@import 'forms';
/*******************************************************
	Utilities
*******************************************************/
@import 'utilities';
/*******************************************************
    Pagination
*******************************************************/
// @import 'pagination';
/*******************************************************
    Buttons
*******************************************************/
// @import 'buttons-links';
/*******************************************************
    Icons & logos
*******************************************************/
// @import 'icons-logos';
/*******************************************************
    Labels
*******************************************************/
// @import 'labels';
/*******************************************************
    Hero
*******************************************************/
@import 'hero';
/*******************************************************
    Accordions
*******************************************************/
// @import 'accordions';
/*******************************************************
    Modals
*******************************************************/
// @import 'modals';
/*******************************************************
    Swipper
*******************************************************/
// @import 'swiper';
/*******************************************************
    Cards
*******************************************************/
// @import 'cards';
/*******************************************************
    Medias
*******************************************************/
// @import 'medias';
/*******************************************************
    Tabs
*******************************************************/
// @import 'tabs';
/*******************************************************
    Footer
*******************************************************/
// @import 'footer';
/*******************************************************
    Print
*******************************************************/
// @import 'print';
