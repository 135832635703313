/*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/
.text-editor {

	ul,
	ol {
		list-style: disc;
		color: inherit;
		padding-left: 20px;

		li {
			padding-bottom: 8px;

			ul,
			ol {
				padding-top: 12px;
			}
		}
	}
	
	ul li {
		color: $color__green !important;
		padding: 3px;
	}
}