/*--------------------------------------------------------------
## FORMS General
## Formie
--------------------------------------------------------------*/
/*-------------------------------------
    PLACEHOLDER
------------------------------------*/
::-webkit-input-placeholder {
	/* Chrome */
	color: $color__grey-medium;
	opacity: 1;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: $color__grey-medium;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $color__grey-medium;
}

:-moz-placeholder {
	/* Firefox 4 - 18 */
	color: $color__grey-medium;
}

/*--------------------------------------------------------------
## Fields
--------------------------------------------------------------*/
input[type="text"],
input[type="text"].fui-input,
input[type="email"],
input[type="email"].fui-input,
input[type="url"],
input[type="url"].fui-input,
input[type="password"],
input[type="password"].fui-input,
input[type="search"],
input[type="search"].fui-input,
input[type="number"],
input[type="number"].fui-input,
input[type="tel"],
input[type="tel"].fui-input,
input[type="range"],
input[type="range"].fui-input,
input[type="date"],
input[type="date"].fui-input,
input[type="month"],
input[type="month"].fui-input,
input[type="week"],
input[type="week"].fui-input,
input[type="time"],
input[type="time"].fui-input,
input[type="datetime"],
input[type="datetime"].fui-input,
input[type="datetime-local"],
input[type="datetime-local"].fui-input,
input[type="color"],
input[type="color"].fui-input,
textarea,
textarea.fui-input,
select,
select.fui-select {
	width: 100%;
	color: $color__grey-text;
	font-size: $font__size-14;
	border: none;
	border-radius: 0px;
	padding: 10px;
	background-color: $color__white;
	height: 56px; //to fit with tailwind

	&:focus {
		// box-shadow: 0 0 3px 1px $color__green-success-25;
	}
	
	&.fui-error {

		// border-color: $color__red-error;
		&:focus {
			// box-shadow: 0 0 3px 1px $color__red-error-25;
		}
	}
}

textarea {
	width: 100%;
	min-height: 225px;
}

/*--------------------------------------------------------------
## Labels
--------------------------------------------------------------*/
.fui-form {
	.fui-field {
		.fui-field-container {
			label.fui-label {
				// color: $color__black;
				// font-weight: $font__weight-bold;
			}
		}
		
		&.fui-error {
			.fui-field-container {
				label.fui-label {
					// color: $color__red-error;
				}
			}
		}
	}
}

form {

	.fui-label,
	label,
	legend{
		display: inline-block;
		font-size: $font__size-14;
		color: $color__black_soft;
		margin-bottom: 6px;

		.on-dark &{
			color: $color__white;
		}
	}
}

/*--------------------------------------------------------------
## input search
--------------------------------------------------------------*/
.input-wrapper-search {
	position: relative;

	.input-search {
		padding-left: 38px !important;
	}
	
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 8px;
		font-family: $font__fa-familly;
		content: "\f002";
		display: block;
		font-weight: 900;
		font-size: 1.25rem;
		transform: translateY(-50%);
		color: $color__aqua;
	}
}

/*--------------------------------------------------------------
## Checkbox
--------------------------------------------------------------*/
.fui-form {

	.fui-type-checkboxes,
	.fui-type-radio-buttons {
		.fui-fieldset {
			&.fui-layout-horizontal {
				.fui-layout-wrap {

					.fui-checkbox,
					.fui-radio {
						margin-right: 16px;
					}
				}
			}
			
			&.fui-layout-vertical {
				.fui-layout-wrap {

					.fui-checkbox,
					.fui-radio {
						margin-bottom: 8px;
					}
				}
			}
			
			.fui-legend {}
			
			.fui-input-container {
				.fui-layout-wrap {
					.fui-checkbox {

						input[type="checkbox"],
						input[type="radio"] {
							font-weight: $font__weight-bold;
						}
						
						label {

							&:before,
							&:after {
								position: absolute;
								top: 2px;
								left: 0px;
								background-color: transparent;
								border-color: $color__black;
								border-radius: 0px;
								height: 1.5rem;
								width: 1.5rem;
							}
						}
						
						input[type="checkbox"],
						input[type="radio"] {
							&:checked+label {
								&:before {
									background-image: none;
								}
								
								&:after {
									top: 2px;
									left: 2px;
									content: "\f00c";
									display: block;
									font-weight: 900;
									font-size: 1.25rem;
								}
							}
						}
					}
					
					.fui-radio {

						input[type="checkbox"],
						input[type="radio"] {
							font-weight: $font__weight-bold;
						}
						
						label {

							&:before,
							&:after {
								position: absolute;
								top: 2px;
								left: 0px;
								background-color: transparent;
								// border-color: $color__grey-lines;
								border-radius: 50%;
								height: 1.5rem;
								width: 1.5rem;
							}
						}
						
						input[type="checkbox"],
						input[type="radio"] {
							&:checked+label {
								&:before {
									// background-color: $color__yellow-bright;
									// border-color: $color__yellow-bright;
									background-image: none;
								}
								
								&:after {
									top: 8px;
									left: 6px;
									height: 9px;
									width: 9px;
									content: "";
									display: block;
									// background-color: $color__green-forest;
								}
							}
							
							&:focus+label {
								&:before {
									// border-color: $color__green-success;
									// box-shadow: 0 0 3px 1px $color__green-success-25;
								}
							}
						}
					}
				}
			}
		}
	}
}

.checkbox-input-wrapper {
	position: relative;

	input[type="checkbox"] {
		display: none;
		font-weight: $font__weight-bold;
	}
	
	label {
		// position: relative;
		margin-left: 2rem;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: transparent;
			border: 1px solid $color__aqua;
			border-radius: 0px;
			height: 1.25rem;
			width: 1.25rem;
		}
	}
	
	input[type="checkbox"] {
		&:checked+label {
			&:before {
				border-color: $color__aqua;
				background-color: $color__aqua;
				background-image: none;
			}
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 1px;
				left: 3px;
				font-family: $font__fa-familly;
				content: "\f00c";
				display: block;
				font-weight: 900;
				font-size: 1rem;
				color: $color__white;
			}
		}
		
		&:focus+label {
			&:before {
				border-color: $color__aqua;
				box-shadow: 0 0 3px 1px $color__aqua;
			}
		}
	}
	
	input[type="checkbox"] {
		&:disabled+label {
			opacity: 0.5;
		}
	}
	
	&.dark {
		label {
			color: $color__white;
			font-weight: $font__weight-medium;

			&:before {
				// background-color: $color__white;
				// border: 1px solid $color__grey-medium-light;
			}
		}
	}
	
	&.heart {
		input[type="checkbox"] {
			&:checked+label {
				&:before {
					background-color: transparent;
				}
				
				&:after {
					top: 0px;
					left: 2px;
					content: "\f004";
					color: $color__aqua;
				}
			}
		}
	}
}

.checkbox-false-wrapper {
	display: flex;
	flex-direction: row;

	.checkbox {
		position: relative;
		background-color: $color__white;
		border: 1px solid $color__aqua;
		border-radius: 0px;
		height: 22px;
		width: 22px;
		margin-right: 12px;

		&.active {
			&:after {
				display: block;
				position: absolute;
				font-family: $font__fa-familly;
				content: "\f00c";
				display: block;
				font-weight: 900;
				font-size: 1rem;
				top: 1px;
				left: 2px;
				content: "\f004";
				color: $color__aqua;
			}
		}
	}
	
	.label {
		color: $color__black_soft;
	}
}

.checkbox-input-wrapper-inverse {
	position: relative;

	input[type="checkbox"] {
		display: none;
		font-weight: $font__weight-bold;
	}
	
	label {
		// position: relative;
		margin-left: 2rem;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: $color__red-pink;
			border: 1px solid $color__white;
			border-radius: 0px;
			height: 1.75rem;
			width: 1.75rem;
		}
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 3px;
			left: 6px;
			font-family: $font__fa-familly;
			content: "\f00d";
			display: block;
			font-weight: 900;
			font-size: 1.25rem;
			color: $color__white;
		}
	}
	
	input[type="checkbox"] {
		&:checked+label {
			&:before {
				border-color: $color__red-pink;
				background-color: $color__white;
				background-image: none;
			}
			
			&:after {
				content: "";
				display: none;
			}
		}
		
		&:focus+label {
			&:before {
				border-color: $color__red-pink;
				box-shadow: 0 0 3px 1px $color__red-pink;
			}
		}
	}
}

/*--------------------------------------------------------------
## Select
--------------------------------------------------------------*/
.select-input-wrapper {
	position: relative;
	width: 100%;
	background-color: $color__grey-light;

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: "";
		top: 50%;
		// width: 8px;
		height: 2px;
		// background-color: $color__grey-light;
		pointer-events: none;
	}
	
	&:before {
		right: 13px;
		transform: rotate(-45deg);
	}
	
	&:after {
		right: 18px;
		transform: rotate(45deg);
	}
	
	select {
		width: 100%;
		min-width: 100%;
		appearance: none;
		color: $color__aqua;
		border: 0px;
		height: 55px;
		background-color: transparent;
		font-weight: $font__weight-bold;
		font-family: $font__heading;
		font-size: $font__size-body;
		padding: 18px 30px 10px 10px;
		border-radius: 0;

		option {
			color: $color__black !important;
			letter-spacing: 1px;
		}
	}
	
	&.slim {
		position: relative;
		top: 2px;
		width: fit-content;
		background-color: transparent;

		&:before,
		&:after {
			top: 43%;
		}
		
		@media (screen(sm)) {
			width: auto;
		}
		
		select {
			font-size: $font__size-14;
			width: auto;
			min-width: unset;
			height: 24px;
			padding: 0px 35px 0px 0px;
		}
	}
}

/*--------------------------------------------------------------
## Files
--------------------------------------------------------------*/
.fui-type-file-upload {
	.fui-field-container {
		position: relative;

		label {
			position: absolute;
			overflow: auto;
			clip: unset;
			width: 100%;
			text-align: center;
			font-size: 1.3125em;
			font-weight: $font__weight-medium;
			height: auto;
			top: 20px;
			display: block;
			z-index: 2;
			pointer-events: none;
		}
		
		.fui-input-container {
			position: relative;
			display: flex;
			gap: 10px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 150px;
			padding: 40px 20px 20px 20px;
			background-color: $color__white;
			border: 2px solid $color__white;
			cursor: pointer;
			transition: background .2s ease-in-out;

			input[type="file"] {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				max-width: 100%;
				color: $color__black;
				padding: 5px;
				background: transparent;
				border: none;

				&::file-selector-button {
					display: flex;
					// flex-direction: column;
					justify-content: center;
					text-align: center;
					width: 100%;
					margin-right: 20px;
					font-weight: $font__weight-bold;
					text-decoration: underline;
					border: none;
					background: transparent;
					padding: 10px 20px;
					// color: $color__green-primary;
					cursor: pointer;

					&:hover {
						// background: #0d45a5;
					}
				}
				
				&:focus {
					border-color: transparent;
					box-shadow: none;
					outline: 0;
				}
			}
			
			&:hover {

				// background: $color__grey-light;
				// border-color: #111;
				.drop-title {
					// color: #222;
				}
			}
		}
	}
}

// input[type=file]::file-selector-button {
//   margin-right: 20px;
//   border: none;
//   background: #084cdf;
//   padding: 10px 20px;
//   border-radius: 10px;
//   color: #fff;
//   cursor: pointer;
//   transition: background .2s ease-in-out;
// }
input[type=file]::file-selector-button:hover {
	// background: #0d45a5;
}

/*--------------------------------------------------------------
## Submit
--------------------------------------------------------------*/
.fui-form {
	.fui-form-container {
		.fui-btn-wrapper {
			button[type="submit"].fui-submit {
				font-family: $font__heading;
				font-weight: $font__weight-regular;
				font-size: 16px !important;
				padding: 0.75rem 1rem !important;
				border-radius: 0px;
				background-color: transparent;
				border: 1px solid $color__black-soft;
				color: $color__black-soft;
				transition: background-color 0.2s linear;
				.user-profile & {
					min-width: 20rem;
				}
				
				&:hover {
					background-color: $color__black-soft;
					color: $color__white;
				}
				
				&:focus {
					// color: $color__grey-verydark;
					border: 2px solid $color__green;
				}

				.on-dark &{
					background-color: $color__black-soft;
					color: $color__white;
					//border: none;

					&:hover {
						background-color: $color__white;
						color: $color__black-soft;
						border-color: transparent;
					}

					&:focus {
						// color: $color__grey-verydark;
						//border: 2px solid $color__green;
					}

				}


			}



		}
	}
}

/*--------------------------------------------------------------
## Required
--------------------------------------------------------------*/
.fui-form {
	.fui-field {
		.fui-field-container {
			.fui-required {
				// color: $color__red-error;
			}
		}
	}
}

/*--------------------------------------------------------------
## Error messages
--------------------------------------------------------------*/
.fui-form {
	.fui-field {
		.fui-field-container {
			.fui-error-message {
				// color: $color__red-error;
			}
		}
	}
}

ul.errors {
	li {
		font-size: $font__size-14;
		font-weight: $font__weight-bold;
		color: $color__red-pink;
		margin-top: 8px;
	}
}

/*--------------------------------------------------------------
## Confirmation messages
--------------------------------------------------------------*/
.fui-alert{
	background-color: transparent !important;
	p{
		text-align: center;
		font-weight: $font__weight-bold;
		color: $color__black;

		.on-dark &{
			color: $color__white;
		}


	}


	.container-user-form &{
		padding-left: 0px;
		p{
			text-align: left;
		}
	}
}

/*--------------------------------------------------------------
## Specific form
--------------------------------------------------------------*/
.fui-form {
	.fui-form-container {
		.fui-page {
			.fui-page-container {
				.fui-page-row {
					.fui-field-container {
						label.fui-label {}
						
						.fui-input-container {}
					}
				}
			}
		}
	}
}