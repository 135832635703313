.hero {
	height: 200px;

	@media (screen(md)) {
		height: 350px;
	}
	
	@media (screen(lg)) {
		height: 400px;
	}
}