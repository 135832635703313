/*creating usefull color classes*/
/*******************************************************
    Text colors
*******************************************************/
.color-white {
	color: $color__white;
}

.color-black {
	color: $color__black;
}

/*******************************************************
    Background colors
*******************************************************/
.back-color-white {
	background-color: $color__white;
}

.back-color-black {
	background-color: $color__black;
}

/*******************************************************
	Background gradient colors
*******************************************************/
.back-gradient-aqua {
	background: linear-gradient(to right, #64C78D, #42BDBE);
}

.back-gradient-orange {
	background: linear-gradient(to right, #FF3740, #FF9A2D);
}

.back-gradient-blue {
	background: linear-gradient(to right, #155D94, #40C3ED);
}

.back-gradient-violet {
	background: linear-gradient(to right, #282374, #FA3584);
}

.back-gradient-violet-lime {
	background: linear-gradient(to right, #D9DA0C, #589F34);
}

.back-gradient-aqua,
.back-gradient-orange,
.back-gradient-blue,
.back-gradient-violet {
	&.animate {
		background-size: 150% 150%;

		&:hover {
			animation: gradient 3s ease infinite;
			background-position: 0% 50%;
		}
	}
}

.back-gradient-grey {
	background: linear-gradient(to right, #e9e9e9, #F5F5F6);
}

.back-gradient-grey-inverse {
	background: linear-gradient(to right, #F5F5F6, #e9e9e9);
}

.user-desc-text-wrapper {
	section {
		&:nth-child(even) {
			background: linear-gradient(to right, #e9e9e9, #F5F5F6);
		}
		
		&:nth-child(odd) {
			background: linear-gradient(to right, #F5F5F6, #e9e9e9);
		}
	}
}

/*******************************************************
	Overlay colors
*******************************************************/
$opacity-overlay: 0.8;

.overlay-black {
	background-color: rgba(0, 0, 0, 0.4);
}

.overlay-aqua {
	background: linear-gradient(to right, #64C78D, #42BDBE);
	opacity: $opacity-overlay;
}

.overlay-green {
	background: linear-gradient(to right, #DFDD0D, #409537);
	opacity: $opacity-overlay;
}

.overlay-green-vertical {
	background: linear-gradient(180deg, #DFDD0D, #409537);
	opacity: $opacity-overlay;
}

.overlay-orange {
	background: linear-gradient(to right, #FF3740, #FF9A2D);
	opacity: $opacity-overlay;
}

.overlay-blue {
	background: linear-gradient(to right, #155D94, #40C3ED);
	opacity: $opacity-overlay;
}

.overlay-violet {
	background: linear-gradient(to right, #282374, #FA3584);
	opacity: $opacity-overlay;
}

/*******************************************************
	Gradient animation
*******************************************************/
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	
	50% {
		background-position: 100% 50%;
	}
	
	100% {
		background-position: 0% 50%;
	}
}